<template>
  <section>
    <headline-group sectionTitle="Una larga experiencia<br>en el sector"
      sectionSubTitle="La mayor calidad y durabilidad a su alcance" orientation="right" />
    <div class="parallax-container">
      <div class="image-container">
        <div class="content">
          <h5>Construímos piscinas<br>desde 1991</h5>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import Rellax from 'rellax';
import HeadlineGroup from "@/components/HeadlineGroup.vue";
export default {
  components: { HeadlineGroup },
  mounted() {
    if (window.innerWidth > 768) {
      new Rellax('.parallax-container');
    }
  }
};
</script>

<style scoped>
.parallax-container {
  width: 100%;
  min-height: 300px;
  max-height: 80vh;
  height: 70vw;
  position: relative;
  overflow: hidden;
  background-image: url('../assets/pool_kids.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.content {
  position: absolute;
  width: 75%;
  height: 50%;
  background-color: var(--main-faded-color);
  z-index: 2;
  left: calc(50% - (75% / 2));
  top: calc(50% - (50% / 2));
  display: flex;
  justify-content: center;
  align-items: center;
}

h5 {
  text-align: center;
  font-size: var(--font-size-4);
  color: white;
  margin: 15px;
  line-height: var(--font-size-3);
}
</style>
