<template>
  <section>
    <headline-group
      sectionTitle="Descubre lo que opinan<br>nuestros clientes"
      sectionSubTitle="Siempre contentos con nuestro servicio"
      orientation="right"
    />
    <div class="reviews-container">
      <div class="content">
        <i class="fa-solid fa-angle-left" @click="prevReview"></i>
        <p class="review-text .no-break">«&nbsp;{{ currentReview }}&nbsp;»</p>
        <i class="fa-solid fa-angle-right" @click="nextReview"></i>
      </div>
    </div>
  </section>
</template>

<script>
import HeadlineGroup from "@/components/HeadlineGroup.vue";

export default {
  components: { HeadlineGroup },
  data() {
    return {
      reviews: [
        "Es una empresa familiar 100% recomendable y muy seria",
        "Excelente servicio y trato con el cliente",
        "Profesionales y atentos en todo momento, una experiencia única",
        "La mejor elección que pudimos hacer, lo recomendados",
      ],
      currentIndex: 0,
    };
  },
  computed: {
    currentReview() {
      return this.reviews[this.currentIndex];
    },
  },
  methods: {
    nextReview() {
      this.currentIndex = (this.currentIndex + 1) % this.reviews.length;
    },
    prevReview() {
      this.currentIndex =
        (this.currentIndex - 1 + this.reviews.length) % this.reviews.length;
    },
  },
};
</script>

<style scoped>
.reviews-container {
  width: 100%;
  min-height: 300px;
  max-height: 70vh;
  height: 50vw;
  position: relative;
  background-color: var(--main-color);
}

.content {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

i {
  height: 100%;
  display: flex;
  justify-content: center;
  width: 25%;
  align-items: center;
  font-size: var(--font-size-2);
  color: #ffff;
  margin: var(--main-margin-sides);
  cursor: pointer;
  transition: color 0.5s;
}

i:hover {
  animation: colorChange 0.5s normal;
}

@keyframes colorChange {
  0% {
    color: #ffff;
  }
  50% {
    color: var(--second-color);
  }
  100% {
    color: #ffff;
  }
}

.review-text {
  display: flex;
  width: 50%;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-4);
  color: white;
  margin: 15px;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.no-break {
  white-space: nowrap;
}
</style>
